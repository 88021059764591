<template>
  <div class="file">
    <div v-if="value">
      <div>
        <a :href="value.url" target="_blank">
          {{ value.url }}
        </a>
        <v-btn icon @click="reset" small title="Удалить">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
        <v-btn @click="selectFile" small icon title="Редактировать">
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
      </div>
      <div class="mt-1">
        ID: {{ value.id }}
      </div>
    </div>
    <div v-else>
      <v-btn @click="selectFile" color="primary">
        Выбрать файл
      </v-btn>
    </div>

    <v-dialog max-width="960" v-model="modal">
      <FilesList
        mode="select"
        @select="onFileSelect"
      />
    </v-dialog>

    <div class="red--text mt-1" v-if="errorMessages">
      {{ errorMessages }}
    </div>
  </div>
</template>

<script>
  import FilesList from '@/components/Files/FilesList.vue'

  export default {
    components: {
      FilesList,
    },
    props: {
      value: {
        type: Object,
      },
      errorMessages: {
        type: String
      }
    },
    data () {
      return {
        modal: false,
      }
    },
    methods: {
      selectFile () {
        this.modal = true
      },
      onFileSelect (file) {
        this.modal = false
        this.$emit('input', file)
      },
      reset () {
        if (confirm('Удалить файл?')) {
          this.$emit('input', null)
        }
      }
    }
  }
</script>
